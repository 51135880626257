import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';

export default function UpcomingEventsGrid({ section }) {
  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= 768 : false
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    flexContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    cardWrapper: {
      flex: isSmallScreen ? '0 0 100%' : '0 0 30%',
      marginBottom: '20px',
    },
    image: {
      width: '300px',
      height: '300px',
      objectFit: 'cover',
    },
    button: {
      backgroundColor: '#800080',
      color: '#fff',
      width: '100%',
      marginTop: '10px',
      borderRadius: '10px',
    },
  };

  return (
    <section className="section">
      {section?.title && (
        <div className="container container--md" style={{ textAlign: 'center' }}>
          <h2 className="section__title">{section.title}</h2>
        </div>
      )}
      <div className="container container--lg">
        <div className="flex-container" style={styles.flexContainer}>
          {section?.events?.map((event, event_idx) => (
            <div key={event_idx} className="card-wrapper" style={styles.cardWrapper}>
              <Card>
                <div style={{ textAlign: 'center'}}>
                  {event.image && (
                    <img
                      src={event.image}
                      alt={event.title}
                      style={styles.image}
                    />
                  )}
                </div>
                <div style={{ textAlign: 'center', marginTop: '5px'}}>
                  <h2><strong>{event.title}</strong></h2>
                  <p>{event.fee}</p>
                </div>
                <p><strong>Date & Time:</strong> {event.dateTime}</p>
                <p><strong>Location:</strong> {event.location}</p>
                <p>{event.description}</p>
                <a href={event.link} target={event.newTab ? "_blank" : "_self"} rel="noopener noreferrer">
                  <Button style={styles.button}>{event.btntext}</Button>
                </a>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
